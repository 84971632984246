$color1: #FEFEFD;
$color2: #848C16;
$color3: #DCB4A5;
$color4: #BC3D64;
$color5: #241828;

$color-light-shades: $color1;
/*
Use this color as the background for your dark-on-light designs, or the text color of an inverted design.
 */
$color-light-accent: $color2;
/*
Accent colors can be used to bring attention to design elements by contrasting with the rest of the palette.
 */
$color-main-brand-color: $color3;
/*
This color should be eye-catching but not harsh. It can be liberally applied to your layout as its main identity.
 */
$color-dark-accent: $color4;
/*
Another accent color to consider. Not all colors have to be used - sometimes a simple color scheme works best.
 */
$color-dark-shades: $color5;
/*
Use as the text color for dark-on-light designs, or as the background for inverted designs.
 */

//https://coolors.co/022b3a-1f7a8c-bfdbf7-e1e5f2-ffffff
$padding-small: 8px;
$padding: 16px;
$padding-large: 24px;
$padding-xlarge: 32px;

/*
$color-darker: #022B3A;
$color-dark: #1f7a8c;
$color-normal: #BFDBF7;
$color-light: #E1E5F2;
$color-lighter: #FFFFFF;
$color-highlight: red;

 */

$color-background: $color-light-shades;
$color-text: $color-dark-shades;
$color-text-darker: $color-dark-shades;
$color-text-invert: $color-light-shades;
$color-highlight: $color-light-accent;
$border-color: $color-main-brand-color;
$outline-color: $color-light-accent;

$border-width: 1px;
$border-style: solid;
$default-radius: 8px;

.roundedBorder {
  // b1order: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: $default-radius;
  border-width: $border-width;
  border-style: $border-style;
  border-color: $border-color;
}

$outline-border: $border-width $border-style $outline-color;

$content-width: 800px;

$font-normal: 1em;
$font-large: 1.1em;
$font-xlarge: 1.2em;
$font-xxlarge: 2em;

*, *::before, *::after {
  font-family: Roboto, sans-serif;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  background-color: $color-background;
}

.mainContent {
  margin: 0 auto;
  padding: 0 $padding $padding $padding;
  max-width: $content-width;
  // background-color: yellow;
}

label, h2, button, span, div, input, textarea, button, p {
  color: $color-text;
}

label, button, span, div, input, textarea, button {
  font-size: $font-normal;
}

h1 {
  text-align: center;
  font-weight: normal;
  font-size: $font-xxlarge;
  color: $color-text-darker;
  padding: 0;
  margin: $padding 0 0 0;

}

h2 {
  text-align: center;
  font-weight: normal;
  font-size: $font-xlarge;
  padding: 0;
  margin: $padding 0 0 0;
  vertical-align: center;
}

h3 {
  font-size: $font-large;
  padding: 0;
  margin: 0;
}

h4 {
  font-size: $font-normal;
  padding: 0;
  margin: 0;
}


input {
  text-align: center;
  background-color: $color-background;
}

button, input {
  padding: $padding-small $padding;
  margin: 0
}

textarea {
  padding: $padding;
  background-color: $color-background;
  margin: 0;
}

.inline {
  margin: $padding 0 0 0;

  label, input, button, div {
    display: inline;
  }
}

.inline::after {
  content: "";
  clear: both;
  display: table;
}

.sectionArea {
  @extend .roundedBorder;
  margin: $padding 0 0 0;
  padding: $padding;
  background-color: $color-background;

  textarea {
    margin: $padding 0 0 0;
  }

  textarea, input {
    @extend .roundedBorder;

    &:focus {
      border-radius: $default-radius;
      outline: $outline-border;
    }
  }

  textarea {
    width: 100%;
    height: 100px;
  }

  label {
    size: $font-large;
  }

  #saveResult {
    margin: 0 0 0 $padding;
    float: right;
    display: none;

    label {
      color: $color-highlight;
    }

    input {
      color: $color-text-invert;
      margin-left: $padding;
      background-color: $color-highlight;
      letter-spacing: .2ch;
      width: 16ch;
    }

    .imageButton {
      margin-left: $padding-small;
    }
  }

  .sectionButton {
    @extend .imageButton;
    float: right;
  }

  .showWhenCollapsed {
    display: none
  }
}

.imageButton {
  object-fit: cover;
  cursor: pointer;
  vertical-align: middle;
  padding-top: -8px;
}

@media (max-width: 480px) {
  .visible {
    display: block !important;
  }
  .loadSection button {
    margin: $padding 0 0 0 !important;
  }

  #saveResult {
    margin: $padding 0 0 0 !important;
    float: none !important;
  }
}

@media (min-width: 481px) {
  .visible {
    display: inline-block !important;
  }
}

.loadSection {
  .inline {
    input, button {
      margin-left: $padding;
    }
    input {
      letter-spacing: .2ch;
      width: 16ch;

    }
  }

  .loadResult {
    margin: $padding 0 0 0;
    display: none;
  }
}

.instructions {
  margin-top: $padding-xlarge;
}
